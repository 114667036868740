import { Client } from '../core/client';
import { TestScenario } from './testScenario';
import * as rt from '../react/main';
//let mt= await ClientCore.create();//;new ClientCore();
//mt.goPing();
//rt.react();

async function main() {
    const client = await Client.create();
    await rt.react(client);
    (window as any).ts = {
        s1:async ()=>{
            let s = new TestScenario(client);
            await s.addProducers(10);
            await s.addConsumers(10);
            s.startJobs(10);
        }
    }
}

main();