import { GenericBaseJob } from "../taskRunner/job/genericBaseJob";
import { BaseData } from "../taskRunner/job/data/baseData";

export class ModelAndBOMJob extends GenericBaseJob<ModelAndBOMJob,MBReq,MBRes> {
    protected getDefaultTaskName(): string {
        return "ModelAndBOMJob";
    }
    protected initialiseRequestData(): MBReq {
        return new MBReq();
    }
    protected initialiseResponseData(): MBRes {
        return new MBRes();
    }
}

export class MBRes extends BaseData {
    public message:string = "";
}

export class MBReq extends BaseData {
    public jobName:string = "";
}