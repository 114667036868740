import { Client } from "../core/client";
import { TestProducer } from "./testProducer";
import { TestConsumer } from "./testConsumer";

export class TestScenario {

    //#region Fields

    
    //public Client client;
    private consumerJobsCancelled:number = 0;
    private consumers:TestConsumer[] = [];
    private jobCount:number = 0;
    private jobsAccepted:number = 0;
    private jobsFinished:number = 0;
    private jobsOffered:number = 0;
    private jobsRequested:number = 0;
    //private object key = new object();
    private producerJobsCancelled:number = 0;
    private producerJobsFinished:number = 0;
    private producerJobsStarted:number = 0;
    private producers:TestProducer[] = [];
    //private ManualResetEvent r = new ManualResetEvent(false);

    //#endregion Fields    

    //#region Constructors
    
    constructor(public client:Client) {

    }

    //#endregion Constructors

    //#region Methods

    public async addConsumers(count:number, factory:(()=>TestConsumer)|undefined = undefined):Promise<void> {
        if (!factory) return this.addConsumers(count,()=>new TestConsumer());
        for (let i=0;i<count;i++) {
            let np = factory();
            await np.setup(this.consumers.length,this);
            this.consumers.push(np);
        }
    }

    public async addProducers(count:number, factory:(()=>TestProducer)|undefined = undefined):Promise<void> {
        if (!factory) return this.addProducers(count,()=>new TestProducer());
        for (let i=0;i<count;i++) {
            let np = factory();
            await np.setup(this.producers.length,this);
            this.producers.push(np);
        }
    }

    public logJobAccepted(endpoint:TestProducer) {
        this.jobsAccepted++;
    }

    public logJobCancelReceived(endpoint:TestProducer|TestConsumer) {
        if (endpoint instanceof TestProducer) this.producerJobsCancelled++;
        if (endpoint instanceof TestConsumer) this.consumerJobsCancelled++;
    }

    public logJobFinished(endpoint:TestProducer|TestConsumer) {
        if (endpoint instanceof TestProducer) this.producerJobsFinished++;
        if (endpoint instanceof TestConsumer) this.jobsFinished++;
        this.checkCompletion();
    }
    
    public logJobOfferReceived(endpoint:TestProducer) {
        this.jobsOffered++;
    }

    public logJobRequest(endpoint:TestConsumer) {
        this.jobsRequested++;
    }

    public logJobStarted(endpoint:TestProducer) {
        this.producerJobsStarted++;
    }

    public async startJobs(count:number):Promise<void> {
        let jc=count;
        while (jc>0) {
            for (let c of this.consumers) {
                this.jobCount++;
                c.startJob(`Job#${this.jobCount}`);
                jc--;
                if (jc==0) break;
            }
        }
    }

    public wait():void {}

    private checkCompletion():void {
        this.printStats();
        if (this.jobsFinished+this.consumerJobsCancelled==this.jobsRequested) {
            console.log("DONE");
        }
    }

    private printStats():void {
        console.log(`Accepted ${this.jobsOffered}/${this.jobsAccepted} Produced ${this.producerJobsFinished}/$this.producerJobsStarted} Jobs (cancelled/completed/requested) ${this.consumerJobsCancelled}/${this.jobsFinished}/${this.jobsRequested}`)
    }


    //#endregion Methods
}
