import { TestScenario } from "./testScenario";
import { Producer } from '../taskRunner/endpoints/producer';
import { ModelAndBOMJob } from "./modelAndBOMJob";
import { SingleJobFactory } from "../taskRunner/job/singleJobFactory";

async function sleep(duration:number):Promise<void> {
    return new Promise<void>((res,rej)=>{
        setTimeout(res,duration);
    });
}

export class TestProducer {
    
    name: string  = "";
    index: number = 0;
    asyncLimit: number = 1;
    scenario!: TestScenario;
    producer: Producer|null = null;
    async setup(index: number, scenario: TestScenario) {
        this.name= `Producer#${index}`;
        this.index = index;
        this.asyncLimit = 1;
        this.scenario = scenario;
        this.producer = await scenario.client.createProducer();
        const jobHandler = this.producer.getJobHandler(new SingleJobFactory(()=>new ModelAndBOMJob));
        //jobHandler
        jobHandler.on("considerJob",this.considerJob);
        jobHandler.on("startJob",this.startJob);
        jobHandler.on("cancelJob",this.cancelJob);
    }

    cancelJob = (job:ModelAndBOMJob,reason:string)=>{
        this.scenario.logJobCancelReceived(this);
    }

    protected onAcceptJob(job: ModelAndBOMJob, producer: Producer):void {
        //throw new Error("Method not implemented.");
        producer.acceptJob(job);
        this.scenario.logJobAccepted(this);
    }

    considerJob = (job:ModelAndBOMJob,producer:Producer)=>{
        this.scenario.logJobOfferReceived(this);
        this.onAcceptJob(job,producer)
    }

    protected async onRunJob(job:ModelAndBOMJob,producer:Producer):Promise<void> {
        await sleep(100);
        job.updateStatus(0.5, "Half Way");
        job.responseData.message = `"${this.name} is almost finished`;
        job.updateStatus("Nearly", true);
        await sleep(100);
        job.updateStatus(1, "Done");
        await sleep(100);
        job.responseData.message = `You have been served by ${this.name}`;
        job.finish();
        this.scenario.logJobFinished(this);
    }

    startJob = (job:ModelAndBOMJob,producer:Producer)=>{
        this.scenario.logJobStarted(this);
        this.onRunJob(job,producer);
    }

}