import { Consumer } from "#@client/taskRunner/endpoints/consumer";
import { SingleJobFactory } from "#@client/taskRunner/job/singleJobFactory";
import { ModelAndBOMJob } from "./modelAndBOMJob";
import { TestScenario } from "./testScenario";

export class TestConsumer {
        
    //#region Fields

    protected consumer!: Consumer;
    protected index: number = 0;
    protected name: string = "";
    protected scenario!: TestScenario;

    //#endregion Fields
    //#region Methods

    async setup(index: number, scenario: TestScenario) {
        this.index = index;
        this.name = `Consumer#${index}`;
        this.scenario = scenario;
        this.consumer = await scenario.client.createConsumer();
    }

    public startJob(name:string) {
        //console.log("startJob",name)
        const job = this.consumer.createJob(new SingleJobFactory(()=>new ModelAndBOMJob));
        job.requestData.jobName=name;
        job.on("cancelled",this.cancelled);
        job.on("finished",this.finished);
        job.on("progressChanged",this.progressChanged);
        job.on("resultsChanged",this.resultsChanged);
        job.on("statusMessageChanged",this.statusMessageChanged);
        job.start();
        this.scenario.logJobRequest(this);
    }

    cancelled=(reason:string,job:ModelAndBOMJob)=>{
        this.scenario.logJobCancelReceived(this);
    }

    finished=(job:ModelAndBOMJob)=>{
        this.scenario.logJobFinished(this);
    }

    progressChanged=(value:number,job:ModelAndBOMJob)=>{
        //this.scenario.logJobFinished(this);
    }

    resultsChanged=(job:ModelAndBOMJob)=>{
        //this.scenario.logJobFinished(this);
    }

    statusMessageChanged=(status:string,job:ModelAndBOMJob)=>{
        //this.scenario.logJobFinished(this);
    }
    
    //#endregion Methods
}